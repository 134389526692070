import PropTypes from 'prop-types';
import './stellair.css'

export const TLSI_SERVICE = {
	LECTUREVITALE: 'lecturevitale',
	INSI: 'insi',
	IMTI: 'imti',
	DMTI: 'dmti',
	AATI: 'aati',
	ATMPI: 'atmpi',
	ALDI: 'aldi'
}

function StellairInputContainer(props) {
	return (
		<form className="form-row" onSubmit={props.send}>
			<div className="col-sm-10">
				<select className="form-control" id="idService" name="idService" defaultValue={TLSI_SERVICE.LECTUREVITALE} onChange={(e) => props.setSelectedService(e.target.value)}>
					<option value={TLSI_SERVICE.LECTUREVITALE}>Lecture carte Vitale</option>
					<option value={TLSI_SERVICE.IMTI}>Informations du médecin traitant</option>
					<option value={TLSI_SERVICE.DMTI}>Déclaration de médecin traitant</option>
					<option value={TLSI_SERVICE.AATI}>Avis d'Arrêt de Travail</option>
					<option value={TLSI_SERVICE.INSI}>Identifiant National de Santé</option>
				</select>
			</div>
			<div className="col-sm-2">
				<button className="section-btn stellairPadding10">Exécuter</button>
			</div>
		</form>
	);
}

StellairInputContainer.propTypes  = {
	send: PropTypes.func.isRequired,
	setSelectedService: PropTypes.func.isRequired,
}

export default StellairInputContainer; 