import PropTypes from 'prop-types';
import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import './stellairPdfContainer.css'

function StellairPdfContainer(props) {
	const { stellairPdf, deleteStellairPdf } = props;
	const [numPages, setNumPages] = useState(null);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}
 
	const stellairPdfIsEmpty = window.$.isEmptyObject(stellairPdf);
	if (stellairPdfIsEmpty) {
		return null;
	}

	return (
		<center>
			<pre>
				<div style={{float: 'right'}}><button onClick={deleteStellairPdf} className="btn btn-danger btn-circle" >x</button></div>
				<div className="all-page-container">
					<Document
						file={stellairPdf}
						options={{ workerSrc: "/pdf.worker.js" }}
						onLoadSuccess={onDocumentLoadSuccess}
					>
						{Array.from(new Array(numPages), (el, index) => (
							<Page key={`page_${index + 1}`} pageNumber={index + 1} />
						))}				
					</Document>
				</div>
			</pre>
		</center>
	);
}


StellairPdfContainer.propTypes = {
	stellairPdf: PropTypes.string.isRequired,
	deleteStellairPdf: PropTypes.func.isRequired,
}

export default StellairPdfContainer;