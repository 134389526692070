function PreLoader() {
	return (
		<section className="preloader" style={{display: 'none'}}>
			<div className="spinner">
				<span className="spinner-rotate" />
			</div>
		</section>
	);
};

export default PreLoader;