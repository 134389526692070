import PropTypes from 'prop-types';

function StellairResultContainer(props) {
	const stellairMessage = props.stellairMessage;
	const stellairMessageIsEmpty = window.$.isEmptyObject(stellairMessage);
	if (!stellairMessageIsEmpty) {
		return (
			<div id="receiverFromStellair" style={{marginTop: '10px'}}>
				<pre>
					<div style={{float: 'right'}}><button onClick={props.deleteStellairMessage} className="btn btn-danger btn-circle" >x</button></div>
					{JSON.stringify(stellairMessage, null, 2) }
				</pre>
			</div>
		);
	}
	return null;
}

StellairResultContainer.propTypes  = {
	stellairMessage: PropTypes.object.isRequired,
	deleteStellairMessage: PropTypes.func.isRequired,
}

export default StellairResultContainer;