import PropTypes from 'prop-types';

import './stellairFrame.css'

function StellairFrame(props) {
	const stellairUrl = props.stellairUrl;
	const stellairHeight = props.stellairHeight + 'px';
	return (
		<div className="modal fade" id="stellairModal" tabIndex="-1" role="dialog" aria-hidden="true">
			<div className="vertical-alignment-helper">
				<div className="modal-dialog vertical-align-center modal-lg">
					<div className="modal-content">
						<div className="modal-body">
							<div className="body-container" style={{height: stellairHeight}}>
								<iframe 
									height="100%"
									width="100%"
									title="Tlsi"
									src={stellairUrl}
									id="stellair-iframe"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> 
	); 
}

StellairFrame.propTypes  = {
	stellairUrl: PropTypes.string.isRequired,
	stellairHeight: PropTypes.number.isRequired,
}

export default StellairFrame; 