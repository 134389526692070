function Header() {
	return (
		<header>
			<div className="container">
				<div className="row">
					<div className="col-md-4 col-sm-5">
						<p>Bienvenue dans notre centre de professionnel de la santé</p>
					</div>
					<div className="col-md-8 col-sm-7 text-align-right">
						<span className="phone-icon"><i className="fa fa-phone" /> 01 14 25 36 90</span>
						<span className="date-icon"><i className="fa fa-calendar-plus-o" /> de 06h00 à 22h00 (du lundi au vendredi)</span>
						<span className="email-icon"><i className="fa fa-envelope-o" /> <a href="#">info@sitepartenaire.fr</a></span>
					</div>
				</div>
			</div>
		</header>
	);
}

export default Header;
