function Appointment() {
	return (
		<section id="appointment" data-stellar-background-ratio={3}>
			<div className="container">
				<div className="row">
					<div className="col-md-6 col-sm-6">
						<img src="images/appointment-image.jpg" className="img-responsive" alt="" />
					</div>
					<div className="col-md-6 col-sm-6">
						<form id="appointment-form" method="post" action="#">
							<div className="section-title wow fadeInUp" data-wow-delay="0.4s">
								<h2>Prendre rendez-vous</h2>
							</div>
							<div className="wow fadeInUp" data-wow-delay="0.8s">
								<div className="col-md-6 col-sm-6">
									<label htmlFor="name">Nom</label>
									<input type="text" className="form-control" id="name" name="name" placeholder="Full Name" />
								</div>
								<div className="col-md-6 col-sm-6">
									<label htmlFor="email">Email</label>
									<input type="email" className="form-control" id="email" name="email" placeholder="Your Email" />
								</div>
								<div className="col-md-6 col-sm-6">
									<label htmlFor="date">Sélectionner une date</label>
									<input type="date" name="date" defaultValue className="form-control" />
								</div>
								<div className="col-md-6 col-sm-6">
									<label htmlFor="select">Sélectionnez un département</label>
									<select className="form-control">
										<option>Santé générale</option>
										<option>Cardiologie</option>
										<option>Dentaire</option>
										<option>Recherche médicale</option>
									</select>
								</div>
								<div className="col-md-12 col-sm-12">
									<label htmlFor="telephone">Numéro de téléphone</label>
									<input type="tel" className="form-control" id="phone" name="phone" placeholder="Phone" />
									<label htmlFor="Message">Message supplémentaire</label>
									<textarea className="form-control" rows={5} id="message" name="message" placeholder="Message" defaultValue={""} />
									<button type="submit" className="form-control" id="cf-submit" name="submit">Soumettre</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Appointment;
