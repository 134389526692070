import PreLoader from './layout/PreLoader';
import Header from './layout/Header';
import Menu from './layout/Menu';
import Stellair from './layout/Stellair/Stellair';
import Home from './layout/Home';
import Team from './layout/Team';
import News from './layout/News';
import Appointment from './layout/Appointment';
import Map from './layout/Map'; 
import Footer from './layout/Footer';

 
function App() {
	return (
		<div id="top" data-spy="scroll" data-target=".navbar-collapse" data-offset="50">
			<PreLoader/>
			<Header/>
			<Menu/>
			<Home/>
			<Team/>
			<Stellair/>
			<News/> 
			<Appointment/>
			<Map/>
			<Footer/>
		</div>
	);
}

export default App;
