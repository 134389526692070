function Team() {
	return (
		<section id="team" data-stellar-background-ratio={1}>
			<div className="container">
				<div className="row">
					<div className="col-md-6 col-sm-6">
						<div className="about-info">
							<h2 className="wow fadeInUp" data-wow-delay="0.1s">Nos médecins</h2>
						</div>
					</div>
					<div className="clearfix" />
					<div className="col-md-4 col-sm-6">
						<div className="team-thumb wow fadeInUp" data-wow-delay="0.2s">
							<img src="images/team-image1.jpg" className="img-responsive" alt="" />
							<div className="team-info">
								<h3>Nate Baston</h3>
								<p>Directeur général</p>
								<div className="team-contact-info">
									<p><i className="fa fa-phone" /> 01 36 25 14 98</p>
									<p><i className="fa fa-envelope-o" /> <a href="#">general@sitepartenaire.fr</a></p>
								</div>
								<ul className="social-icon">
									<li><a href="#" className="fa fa-linkedin-square" >{null}</a></li>
									<li><a href="#" className="fa fa-envelope-o" >{null}</a></li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-md-4 col-sm-6">
						<div className="team-thumb wow fadeInUp" data-wow-delay="0.4s">
							<img src="images/team-image2.jpg" className="img-responsive" alt="" />
							<div className="team-info">
								<h3>Jason Stewart</h3>
								<p>Grossesse</p>
								<div className="team-contact-info">
									<p><i className="fa fa-phone" /> 01 36 25 14 99</p>
									<p><i className="fa fa-envelope-o" /> <a href="#">grossesse@sitepartenaire.fr</a></p>
								</div>
								<ul className="social-icon">
									<li><a href="#" className="fa fa-facebook-square"  >{null}</a></li>
									<li><a href="#" className="fa fa-envelope-o"  >{null}</a></li>
									<li><a href="#" className="fa fa-flickr"  >{null}</a></li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-md-4 col-sm-6">
						<div className="team-thumb wow fadeInUp" data-wow-delay="0.6s">
							<img src="images/team-image3.jpg" className="img-responsive" alt="" />
							<div className="team-info">
								<h3>Miasha Nakahara</h3>
								<p>Cardiologie</p>
								<div className="team-contact-info">
									<p><i className="fa fa-phone" />  01 36 25 14 97</p>
									<p><i className="fa fa-envelope-o" /> <a href="#">cardio@sitepartenaire.fr</a></p>
								</div>
								<ul className="social-icon">
									<li><a href="#" className="fa fa-twitter" >{null}</a></li>
									<li><a href="#" className="fa fa-envelope-o" >{null}</a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Team;