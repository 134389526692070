
import { useEffect, useState } from "react";
import StellairFrame from './StellairFrame';
import StellairResultContainer from './StellairResultContainer';
import StellairPdfContainer from "./StellairPdfContainer";
import StellairInputContainer, { TLSI_SERVICE } from './StellairInputContainer';
import StellairInputPatient from './StellairInputPatient';
import './stellair.css'

// const stellairUrlDev = "https://dev.tlsi.stellair.fr/apptlsi";
// const stellairUrlDev = "https://localhost.tlsi.stellair.fr:8091/apptlsi";
const stellairUrlDev = "http://localhost.stellair.fr:3000/apptlsi";

const stellairUrl = process.env.REACT_APP_STELLAIR_URL || stellairUrlDev;

const stellairObject = {
	type: 'MSG_STELLAIR',
	service: '',
	payload: null,
}
function Stellair() {
	const [stellairHeight, setStellairHeight] = useState(180)
	const [stellairMessage, setStellairMessage] = useState({})
	const [stellairPdf, setStellairPdf] = useState('')
	const [stellairPatients, setStellairPatients] = useState([])
	const [stellairSelectedPatientId, setStellairSelectedPatientId] = useState('')
	const [selectedService, setSelectedService] = useState(TLSI_SERVICE.LECTUREVITALE);

	const sendToStellair = (event) => {
		event.preventDefault();
		document.getElementById("showStellairModal").click();
		stellairObject.payload = { patientId: stellairSelectedPatientId };
		stellairObject.service = selectedService;
		if (selectedService === 'aati') {
			stellairObject.payload = { 
				...stellairObject.payload,
				nomAppli: 'SitePartenaire'
			}
		}
		const iframeStellair = document.getElementById("stellair-iframe").contentWindow;
		iframeStellair.postMessage(stellairObject, stellairUrl);
		setStellairPdf('');
	}

	const hideStellairModal = () => {
		window.$('#stellairModal').modal('hide');
	}

	const messageHandler = (event) => {
		// Handle iframe messages only from stellair
		if (event.origin === (new URL(stellairUrl)).origin) {
			const payload = event?.data?.payload;
			switch (event?.data?.type) {
			case 'END_PROCESS_FROM_STELLAIR':
				hideStellairModal();
				break;
			case 'HEIGHT_FROM_STELLAIR':
				setStellairHeight(payload.result);
				break;
			case 'PDF_FROM_STELLAIR':
				setStellairPdf(payload.result);
				break;
			case 'MSG_FROM_STELLAIR':
				const message = payload.result
				setStellairMessage(message);
				if (TLSI_SERVICE.LECTUREVITALE === payload.service) {
					if (!message.errorCode && !message.errorMsg) {
						setStellairPatients(message.liste);
						setStellairSelectedPatientId(message.liste[0]?.id);
					}
				}
				break;
			default:
				break; 
			}
		}
	}

	useEffect(() => {
		window.addEventListener("message", messageHandler, false);

		return () => {
			window.removeEventListener("message", messageHandler, false);
		}
	});
	return (
		<>
			<section id="stellair" className="slider" data-stellar-background-ratio="0.5">
				<div className="container">
					<div className="row">
						<div className="item item-third">
							<div className="caption">
								<div className="col-md-offset-1 col-md-10">
									<h3>Services SESAM-Vitale avec</h3>
									<div><img src="images/Olaqin.svg" alt="Olaqin" style={{ minWidth: '250px', width: '50%', height: 'auto' }} /></div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</section>
			{stellairPatients.length > 0 &&
				<div className="container stellairPadding10">
					<StellairInputContainer send={sendToStellair} setSelectedService={setSelectedService} />
					<StellairInputPatient stellairPatients={stellairPatients} onChange={(e) => setStellairSelectedPatientId(e.target.value)} />
				</div>
			}
			{stellairPatients.length === 0 && <center className="stellairPadding10"><button className="section-btn smoothScroll" onClick={sendToStellair}>Lire carte Vitale</button></center>}
			<button className="hidden" data-toggle="modal" data-target="#stellairModal" id="showStellairModal" />
			<StellairResultContainer stellairMessage={stellairMessage} deleteStellairMessage={() => setStellairMessage({})} />
			<StellairPdfContainer stellairPdf={stellairPdf} deleteStellairPdf={ () => setStellairPdf('')} />
			<StellairFrame stellairUrl={stellairUrl} stellairHeight={stellairHeight} />
			{/*<iframe
				width="100%"
				height={stellairHeight}
				title="Tlsi"
				src={'http://localhost.stellair.fr:3001/apptlsi/imti?patientId=62bba8e8-e7e2-4289-9806-ff4ddf035d8a'}
				scrolling="yes"
				frameBorder={0}
				id="stellair-iframe2"
			/>*/}
		</>
	);
}


export default Stellair;
