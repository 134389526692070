function Menu() {
	return (
		<section className="navbar navbar-default navbar-static-top" role="navigation">
			<div className="container">
				<div className="navbar-header">
					<button className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
						<span className="icon icon-bar" />
						<span className="icon icon-bar" />
						<span className="icon icon-bar" />
					</button>
					<a href="/" className="navbar-brand"><i className="fa fa-medkit" /> Sitepartenaire</a>
				</div>
				<div className="collapse navbar-collapse">
					<ul className="nav navbar-nav navbar-right">
						<li><a href="#home" className="smoothScroll">Accueil</a></li>
						<li><a href="#team" className="smoothScroll">Médecins</a></li>
						<li><a href="#stellair" className="smoothScroll">TLSi</a></li>
						<li><a href="#news" className="smoothScroll">Nouveautés</a></li>
						<li><a href="#google-map" className="smoothScroll">Contact</a></li>
						<li className="appointment-btn"><a href="#appointment">Prendre rendez-vous</a></li>
					</ul>
				</div>
			</div>
		</section>
	);
}

export default Menu;