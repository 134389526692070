import PropTypes from 'prop-types';

import './StellairInputPatient.css'

function StellairInputPatient(props) {
	const patients = props.stellairPatients;
	return (
		<div className="row">
			{patients.map((patient, index) =>
				<div className="col-sm-4" key={patient.id}>
					<label>
						<input type="radio" onChange={props.onChange} value={patient.id} id="selectedPatient" name="selectedPatient" className="card-input-element" defaultChecked={index === 0} />
						<div className="panel panel-default card-input">
							<div className="panel-heading">{`${patient.prenom} ${patient.nom}`}</div>
							<div className="panel-body">
								<p>{`né(e) le ${patient.dateNaissanceEnCarte}`}</p>
								<span>{`${patient.nirAssure} ${patient.cleNirAssure}`}</span>
							</div>
							<div className="panel-footer light-color"><center>{patient.id}</center></div>
						</div>
					</label>
				</div>
			)}
		</div>
	);
}

StellairInputPatient.propTypes = {
	stellairPatients: PropTypes.array.isRequired,
}

export default StellairInputPatient;