function Footer() {
	return (
		<footer data-stellar-background-ratio={5}>
			<div className="container">
				<div className="row">
					<div className="col-md-4 col-sm-4">
						<div className="footer-thumb"> 
							<h4 className="wow fadeInUp" data-wow-delay="0.4s">Informations de contact</h4>
							<p>Fusce at libero iaculis, venenatis augue quis, pharetra lorem. Curabitur ut dolor eu elit consequat ultricies.</p>
							<div className="contact-info">
								<p><i className="fa fa-phone" /> 01 14 25 36 90</p>
								<p><i className="fa fa-envelope-o" /> <a href="#">info@sitepartenaire.fr</a></p>
							</div>
						</div>
					</div>
					<div className="col-md-4 col-sm-4"> 
						<div className="footer-thumb"> 
							<h4 className="wow fadeInUp" data-wow-delay="0.4s">Dernières nouvelles</h4>
							<div className="latest-stories">
								<div className="stories-image">
									<a href="#"><img src="images/news-image.jpg" className="img-responsive" alt="" /></a>
								</div>
								<div className="stories-info">
									<a href="#"><h5>Technologie incroyable</h5></a>
									<span>08 mars 2021</span>
								</div>
							</div>
							<div className="latest-stories">
								<div className="stories-image">
									<a href="#"><img src="images/news-image.jpg" className="img-responsive" alt="" /></a>
								</div>
								<div className="stories-info">
									<a href="#"><h5>Nouveau processus de guérison</h5></a>
									<span>20 février 2021</span>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-4 col-sm-4"> 
						<div className="footer-thumb">
							<div className="opening-hours">
								<h4 className="wow fadeInUp" data-wow-delay="0.4s">Horaires d'ouvertures</h4>
								<p>Lundi - Vendredi <span>06h00 à 22h00</span></p>
								<p>Samedi <span>09h00 à 20h00</span></p>
								<p>Dimanche <span>Fermé</span></p>
							</div> 
							<ul className="social-icon">
								<li><a href="#" className="fa fa-facebook-square" attr="facebook icon" >{null}</a></li>
								<li><a href="#" className="fa fa-twitter" >{null}</a></li>
								<li><a href="#" className="fa fa-instagram" >{null}</a></li>
							</ul>
						</div>
					</div>
					<div className="col-md-12 col-sm-12 border-top">
						<div className="col-md-4 col-sm-6">
							<div className="copyright-text"> 
								<p>Copyright © 2021 Sitepartenaire
                    | Design: Tooplate</p>
							</div>
							{/*<!--
                    Template 2098 Health
                    http://www.tooplate.com/view/2098-health
                -->*/}
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="footer-link"> 
								<a href="#">Tests de laboratoire</a>
								<a href="#">Départements</a>
								<a href="#">Police d'assurance</a>
								<a href="#">Carrières</a>
							</div>
						</div>
						<div className="col-md-2 col-sm-2 text-align-center">
							<div className="angle-up-btn"> 
								<a href="#top" className="smoothScroll wow fadeInUp" data-wow-delay="1.2s"><i className="fa fa-angle-up" /></a>
							</div>
						</div>   
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;